<template>
  <div style="max-width: 100rem; margin: auto;">
    <b-card title="Alert">
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item._id"
          :key="item._id"
          ref="row"
        >

          <!-- Item Name -->
          <b-col md="2">
            <b-form-group
              label="Item Name"
              label-for="item-name"
            >
              <b-form-input
                id="item-name"
                type="text"
                placeholder="Title"
                v-model="item.title"
              />
            </b-form-group>
          </b-col>

          <!-- Cost -->
          <b-col md="5">
            <b-form-group
              label="Cost"
              label-for="cost"
            >
              <b-form-input
                id="cost"
                type="text"
                v-model="item.message"
                placeholder="Message"
              />
            </b-form-group>
          </b-col>

          <!-- Quantity -->
          <b-col md="2">
            <b-form-group
              label="Variant"
              label-for="quantity"
            >
              <b-form-select
              v-model="item.variant"
                :options="variants"
              />
            </b-form-group>
          </b-col>

          <!-- Profession -->
          <b-col
            lg="1"
            md="1"
          >
            <b-form-group
              label="Show"
              label-for="price"
            >
              <b-form-checkbox
                class="mt-0 mt-md-1"
                v-model="item.show"
              >
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mt-0 mt-md-2"
              @click="saveAlert(item)"
            >
              <feather-icon icon="SaveIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon mt-0 mt-md-2"
              @click="removeItem(item._id)"
            >
              <feather-icon icon="XCircleIcon" />
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </b-form>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-25"
        />
        <span>Add New</span>
      </b-button>
      
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,  BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BCardHeader, BCardTitle, BCardBody, BForm, BFormCheckbox, BFormSelect
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import RepositoryFactory from '../../api/RepositoryFactory'
const Settings = RepositoryFactory.get('settingsmanager')
import { heightTransition } from '@core/mixins/ui/transition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BCardActions,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormSelect
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      variants:[
        "success",
        "warning",
        "danger"
      ],
    }
  },
  created(){
    window.addEventListener('resize', this.initTrHeight)
    Settings.fetchAlerts().then(rs=>{
      this.items = rs.data.data
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods:{
    repeateAgain() {
      Settings.createAlert().then(rs=>{
        this.items.push(rs.data.alert)
        this.$nextTick(() => {
          console.log(this.$refs.row[0].offsetHeight)
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })
      })
    },
    removeItem(id) {
      Settings.deleteAlert(id).then(rs=>{
        let index = this.items.findIndex(x=>x._id == id)
        if(index > -1){
          this.items.splice(index, 1)
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
        }
      });
    },
    saveAlert(item){
      Settings.saveAlert(item._id,item).then(rs=>{
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>